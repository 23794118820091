<template>
  <div class="reservation-add-customer-note">
    <div class="reservation-add-customer-note--content">
      <div
        v-show="isInDispatchSidebar"
        class="reservation-add-customer-note--back-arrow"
      >
        <a class="arrow_back" @click="clickBackContactNote">
          <CRIcon view-box="0 0 24 24" :width="20" :height="20">
            arrow_back
          </CRIcon>
          Back To Reservation Assignment
        </a>
      </div>
      <CRRichText
        id="reservation-add-customer-note-form-text-area-customer-note"
        :note="previousContactNote"
        placeholder="Notes about the customer for internal use (customers and drivers will not see this)."
        :rows="4"
        auto-grow
        hide-details
        @htmlchange="updateContactNoteHtml"
        @textchange="updateContactNoteText"
      />
    </div>
    <div class="reservation-add-customer-note--btn-spacer"></div>
    <v-btn
      v-if="enableEditContactNotes && customerNotesExist"
      :id="`quote-form-trip-${activeCustomer.id}-delete-customer-notes-dialog-button`"
      color="error"
      outline
      style="margin: 0 0 71px 0; border-radius: 0"
      class="reservation-add-customer-note--action-btn reservation-add-customer-note--delete-btn"
      @click="deleteContactNote"
    >
      Delete
    </v-btn>
    <v-btn
      v-if="enableEditContactNotes"
      :id="`quote-form-trip-${activeCustomer.id}-set-customer-notes-button`"
      class="reservation-add-customer-note--action-btn"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="addContactNote"
    >
      Save
    </v-btn>
  </div>
</template>
<script>
import { authComputed } from '@/state/helpers'
import customers from '@/services/customers'
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    customerId: {
      type: Number,
      default: 0,
    },
    activeCustomer: { type: Object, default: () => {} },
  },
  data() {
    return {
      previousContactNote: '',
      activeContactNote: '',
      activeContactNoteHtml: '',
      loading: false,
      sendEmail: true,
      currentCustomerId: null,
      enableEditContactNotes: true,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showAddContactNote: 'dispatchNew/showAddContactNote',
      showAddTripContactNote: 'dispatchNew/showAddTripContactNote',
    }),
    customerNotesExist() {
      return (
        (this.activeCustomer &&
          this.activeCustomer.notes &&
          this.activeCustomer.notes.length) ||
        (this.activeCustomer &&
          this.activeCustomer.customerNotes &&
          this.activeCustomer.customerNotes[0] &&
          this.activeCustomer.customerNotes[0].html &&
          this.activeCustomer.customerNotes[0].html.length) ||
        (this.activeCustomer &&
          this.activeCustomer.customerNotes &&
          this.activeCustomer.customerNotes[0] &&
          this.activeCustomer.customerNotes[0].note &&
          this.activeCustomer.customerNotes[0].note.length)
      )
    },
    isInDispatchSidebar() {
      return this.showAddContactNote || this.showAddTripContactNote
    },
  },
  mounted() {
    this.activeContactNote =
      this.activeCustomer.customerNotes?.[0]?.html ||
      this.activeCustomer.customerNotes?.[0]?.note ||
      this.activeCustomer.notes ||
      ''
    this.previousContactNote = this.activeContactNote
  },
  methods: {
    ...mapActions({
      setShowAddContactNote: 'dispatchNew/setShowAddContactNote',
      setShowAddTripContactNote: 'dispatchNew/setShowAddTripContactNote',
    }),
    clickBackContactNote() {
      this.setShowAddContactNote(false)
      this.setShowAddTripContactNote(false)
    },
    async submitCustomerNoteUpdate() {
      const payload = this.activeCustomer
      const params = {
        id: this.currentCustomerId,
        payload: payload,
      }
      if (this.customerId !== 0) {
        await customers.updateCustomer(params)
      }
      this.$forceUpdate()
    },
    updateContactNoteText(value) {
      this.activeContactNote = value
    },
    updateContactNoteHtml(value) {
      this.activeContactNoteHtml = value
    },
    addContactNote() {
      if (this.isInDispatchSidebar) {
        this.currentCustomerId = this.activeCustomer.customerId
      } else {
        this.currentCustomerId = this.activeCustomer.id
      }
      this.$set(this.activeCustomer, 'notes', this.activeContactNote)
      EventBus.$emit(
        'trip-contact-notes-update',
        this.activeContactNote,
        this.activeContactNoteHtml
      )
      EventBus.$emit(
        'customer-notes-update',
        this.activeContactNote,
        this.activeContactNoteHtml
      )

      this.submitCustomerNoteUpdate()
      this.setShowAddContactNote(false)
      this.setShowAddTripContactNote(false)
      this.close()
    },
    deleteContactNote() {
      if (this.isInDispatchSidebar) {
        this.currentCustomerId = this.activeCustomer.customerId
      } else {
        this.currentCustomerId = this.activeCustomer.id
      }
      this.$set(this.activeCustomer, 'notes', '')
      this.activeContactNote = ''
      this.activeContactNoteHtml = ''
      this.submitCustomerNoteUpdate()
      this.setShowAddContactNote(false)
      this.setShowAddTripContactNote(false)
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-add-customer-note {
  height: 100%;
  width: 500px;

  &--btn-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    border: none;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
  }

  &--content {
    flex: 1;
    margin: 20px 48px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;
      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
